.footer-widget {
  padding: 40px 0;
}
.header {
  padding: 8px;
}
li.list-buttons.ms-2 {
  margin-top: 13px;
}

@media (max-width: 992px){
  .navigation-portrait .nav-brand {
      margin-left: 0px;
      flex: 1;
      margin-top: 0;
  }
}
.soft-block {
  min-height: 140px;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  max-height: 140px;
}
.soft-block .img-fluid {
  pointer-events: none;
}
.how-icon img{
  width: 50px;
}
.animate__animated{
  opacity: 0;
}
.opacity0{
  opacity: 0;
}
.opacity1{
  opacity: 0;
}